/* eslint-disable quote-props */
import mexicoFlag from '../assets/images/mexico-circle.svg';
import colombiaFlag from '../assets/images/colombia-circle.svg';

export const literalState = {
  RETURN: 'Pendiente pago devolución',
};

export const states = [
  {
    value: 'Envío pendiente por pago',
    text: 'Envío pendiente por pago',
  },
  {
    value: 'Procesando tu envío',
    text: 'Procesando tu envío',
  },
  {
    value: 'Envío programado',
    text: 'Envío programado',
  },
  {
    value: 'Recolección programada',
    text: 'Recolección programada',
  },
  {
    value: 'Recogido por la transportadora',
    text: 'Recogido por la transportadora',
  },
  {
    value: 'En transporte',
    text: 'En transporte',
  },
  {
    value: 'En ciudad destino',
    text: 'En ciudad destino',
  },
  {
    value: 'Distribución',
    text: 'Distribución',
  },
  {
    value: 'Entregado',
    text: 'Entregado',
  },
  {
    value: 'Envío cancelado',
    text: 'Envío cancelado',
  },
  {
    value: 'Devuelto',
    text: 'Devuelto',
  },
  {
    value: 'Pérdida del producto',
    text: 'Pérdida del producto',
  },
  {
    value: literalState.RETURN,
    text: literalState.RETURN,
  },
  {
    value: 'Envío con novedad',
    text: 'Envío con novedad',
  },
];

export const documentTypeOptions = [
  {
    value: 'NIT',
    label: 'NIT',
  },
  {
    value: 'CC',
    label: 'Cédula de ciudadanía',
  },
  {
    value: 'PA',
    label: 'Pasaporte',
  },
  {
    value: 'CE',
    label: 'Cédula de extranjería',
  },
];

export const personTypeOptions = [
  {
    value: 'Persona Natural',
    label: 'Persona Natural',
  },
  {
    value: 'Persona Jurídica',
    label: 'Persona Jurídica',
  },
];

export const personTypeOptionsMexico = [
  { value: 'Persona Física', label: 'Persona Física' },
  { value: 'Persona Moral', label: 'Persona Moral' },
];

export const methodOfPaymentOptionsMexico = [
  { value: 'Tarjeta débito', label: 'Tarjeta débito' },
  { value: 'Tarjeta crédito', label: 'Tarjeta crédito' },
  { value: 'Transferencia electrónica de fondos', label: 'Transferencia electrónica de fondos' },
];

export const averageShipmentsOptions = [
  {
    value: '1 a 60',
    label: '1 a 60 envíos al mes',
  },
  {
    value: '60 a 200',
    label: '60 a 200 envíos al mes',
  },
  {
    value: '200 a 500',
    label: '200 a 500 envíos al mes',
  },
  {
    value: '500 a 5.000',
    label: '500 a 5.000 envíos al mes',
  },
  {
    value: 'Más de 5.000',
    label: 'Más de 5.000 envíos al mes',
  },
];

export const countries = [
  {
    value: '170',
    label: 'Colombia',
    img: colombiaFlag,
  },
  {
    value: '484',
    label: 'México',
    img: mexicoFlag,
  },
];

export const BankList = (bankTransferFee) => [
  'BANCOLOMBIA',
  'NEQUI',
  'DAVIPLATA',
  'BANCO AV VILLAS',
  'BANCO CAJA SOCIAL',
  'BANCO DAVIVIENDA',
  'BANCO DE BOGOTA',
  'BANCO DE OCCIDENTE',
  'BANCO FINANDINA',
  'BANCO GNB SUDAMERIS',
  'BANCO MULTIBANK',
  'BANCO POPULAR',
  'BANCO SANTANDER',
  'BANCOOMEVA',
  'BBVA',
  'CITIBANK',
  'COLPATRIA',
  'COLTEFINANCIERA',
  'FALLABELLA',
  'ITAU',
].map((bank) => `${bank}-(Desembolse: $${bankTransferFee[bank.toLowerCase()]})`);

export const documentTypeList = [
  {
    value: 'CC',
    label: 'Cédula de ciudadanía',
  },
  {
    value: 'PA',
    label: 'Pasaporte',
  },
  {
    value: 'CE',
    label: 'Cédula de extranjería',
  },
  {
    value: 'NIT',
    label: 'NIT',
  },
];

export const routeTypes = {
  nacional: 'Nacional',
  local: 'Local',
  regional: 'Población lejana',
  reexpedicion: 'Población lejana',
  especial: 'Población lejana',
  zonal: 'Población lejana',
};

export const documentTypeName = {
  'CC': 'Cédula de ciudadanía',
  'PA': 'Pasaporte',
  'CE': 'Cédula de extranjería',
  'NIT': 'NIT',
};

export const currencyFractionDigits = {
  COP: 0,
  MXN: 2,
  ARS: 2,
  170: 0,
  484: 2,
  '032': 2,
};

export const countryCode = {
  170: '170',
  484: '484',
  '032': '032',
};

export const prefixCountry = {
  170: '57',
  484: '52',
  '032': '54',
};

export const localeCurrency = {
  COP: 'CO',
  MXN: 'MX',
  ARS: 'AR',
  170: 'CO',
  484: 'MX',
  '032': 'AR',
};

export const currentCurrency = {
  170: 'COP',
  484: 'MXN',
  '032': 'ARS',
};
export const typeDelivery = {
  170: 'transportadora',
  484: 'paquetería',
};
export const typeTextDelivery = {
  484: 'la Secretaría de Comunicaciones y Transportes',
  170: 'el Ministerio de Transporte',
};

export const apiKeyPriceLimit = {
  484: 150,
  170: 10000,
  '032': 5000,
};

export const searchTyoe = personTypeOptions.text;
export const searchDoc = documentTypeList.label;

export const validateUserInfo = (user) => {
  const {
    businessName, name, locationCode, address, cellPhone,
  } = user;
  return !!(businessName && name && locationCode && address && cellPhone);
};

export const validateUserBillingData = (user) => {
  const { invoicingData } = user;
  if (user.countryCode === '484') {
    return !!(
      invoicingData
      && invoicingData?.RFC
      && user.tradeName
      && invoicingData?.postalCode
      && user.personType
      && invoicingData?.taxRegime
      && invoicingData.taxResidence
      && invoicingData?.methodOfPayment
      && invoicingData?.CFDI
      && invoicingData?.emailForBilling
    );
  }
  if (user.countryCode === '170') {
    return !!(
      user.documentType
      && user.documentNumber
      && user.personType
      && invoicingData?.address
      && invoicingData?.locationCode
      && invoicingData?.emailForBilling
    );
  }
  return null;
};

export const formatNumberAsCurrency = (number) => number.toLocaleString('es-ES');

export default states;
