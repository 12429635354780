import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../../components/typography';
import { cashThousandPoint } from '../../../../utils/strings';

import './movementsItem.scss';

const MovementsItem = ({
  date,
  description,
  typeTransaction,
  value,
  notifications,
  currencyToPay,
}) => {
  const [activeToggle, setActiveToggle] = useState(false);
  return (
    <div className="item-list">
      <div className="container-item">
        <div className="container-left">
          <Typography
            text={new Date(date).toLocaleDateString()}
            type="superTitle"
            className="info-item"
          />
          <Typography
            text={description}
            type="paragraph"
            className="info-item"
          />
        </div>
        <div className="container-right">
          <Typography
            text={typeTransaction === 'income' ? `$${cashThousandPoint(parseInt(value ?? 0, 10))}` : `-$${cashThousandPoint(parseInt(value ?? 0, 10))}`}
            type="superTitle"
            className={`cash-value ${typeTransaction !== 'income' ? 'expense' : 'income'}`}
          />
          <Typography text={currencyToPay} className="currencyToPayText" />
          {!notifications && (
            <div className="block" />
          )}
          {notifications && (
            <div className={`image ${activeToggle ? 'displayed' : ''}`} onClick={() => setActiveToggle(!activeToggle)} />
          )}
        </div>
      </div>
      {(notifications && activeToggle) && (
        <div className="detail-container">
          <Typography
            text="Total notificaciones"
            type="title"
            className="title-notifications"
          />
          <div className="list-notifications">
            {notifications.map((item, index) => (
              <div key={`${index + 1}`} className="container-list-notification">
                <Typography
                  text={item.description}
                  type="paragraph"
                  className="item-notifications"
                />
                <Typography
                  text={`$${item.fee}`}
                  type="paragraph"
                  className="item-notifications"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

MovementsItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  typeTransaction: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  currencyToPay: PropTypes.string.isRequired,
  notifications: PropTypes.shape(),
};

MovementsItem.defaultProps = {
  notifications: null,
};

export default MovementsItem;
