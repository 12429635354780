/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as Sentry from '@sentry/react';
import React from 'react';
import { Provider } from 'react-redux';
import RouterApp from './router';

import initStore from './store/store';
import './index.scss';

// Sentry.init({
//   dsn: 'https://3d5f3a2d79704ac3d7c28e352832d656@o465188.ingest.us.sentry.io/4507664357523456',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['https://app.mipaquete.com'],
//   // Session Replay
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const App = (
  <Provider store={initStore()}>
    <RouterApp />
  </Provider>
);

export default App;
