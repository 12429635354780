import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../typography';

import './cardImageButton.scss';

const CardImageButton = ({
  imgRight,
  imgRightDisabled,
  disabled,
  imageButtonText,
  imageButtonDescription,
  imgLeft,
  imgLeftDisabled,
  onClick,
}) => {
  const handelrClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <div
      id="card-image-button-component"
      className={disabled ? 'disabled' : ''}
      onClick={handelrClick}
    >
      <div>
        {imgLeft && imgLeftDisabled && (
          <img
            src={disabled ? imgLeftDisabled : imgLeft}
            alt="imagen Bancaria"
            className="img-left"
          />
        )}
        <div className="container-image-button-text">
          <Typography
            text={imageButtonText}
            type="field-gray"
            className="color-darker"
          />
          <Typography
            text={imageButtonDescription}
            type="value"
            className="description"
          />
        </div>
      </div>
      {imgRight && imgRightDisabled && (
        <img
          src={disabled ? imgRightDisabled : imgRight}
          alt="imagen derecha"
          className="img-right"
        />
      )}
    </div>
  );
};

CardImageButton.propTypes = {
  imgRight: PropTypes.string,
  imgRightDisabled: PropTypes.string,
  disabled: PropTypes.bool,
  imageButtonText: PropTypes.string.isRequired,
  imageButtonDescription: PropTypes.string.isRequired,
  imgLeft: PropTypes.string,
  imgLeftDisabled: PropTypes.string,
  onClick: PropTypes.func,
};

CardImageButton.defaultProps = {
  imgRight: null,
  imgRightDisabled: null,
  disabled: false,
  imgLeft: null,
  imgLeftDisabled: null,
  onClick: null,
};

export default CardImageButton;
