import { localeCurrency } from 'constants/general';

let isKustomerInitialized = false;

export const loadKustomerChat = (user, onConversationCreate, isMobile = false) => {
  const startHandler = () => {
    if (window.Kustomer) {
      window.Kustomer.addListener('onConversationCreate', (res) => {
        const conversationData = {
          conversationId: res.conversationId,
          customAttributes: {
            countryTree: localeCurrency[user.countryCode]?.toLowerCase() ?? 'co',
            customerNameStr: `${user.name.trim()} ${user.surname}`,
          },
        };
        window.Kustomer.describeConversation(conversationData);
        if (onConversationCreate) {
          onConversationCreate(res);
        }
      });
      const customerData = {
        attributes: {
          emails: [user.email],
        },
      };
      window.Kustomer.describeCustomer(customerData);
      if (isMobile) {
        window.Kustomer.open();
      }
    } else {
      console.error('Kustomer SDK not loaded yet');
    }
  };

  // Solo carga el SDK si no ha sido inicializado antes
  if (!isKustomerInitialized) {
    if (window.Kustomer?.start) {
      window.Kustomer.start(
        {
          brandId: '66c5ee28ae777be6a11021c1',
          assistantId: '66c5ed3f3c0ae9a3f2291e6e',
          hideChatIcon: isMobile,
          chatIconSize: 48,
          chatIconPosition: {
            alignment: 'left',
            verticalPadding: 20,
            width: 200,
          },
        },
        () => {
          startHandler();
        },
      );
      isKustomerInitialized = true;
    } else {
      console.error('Kustomer SDK no está cargado');
    }
  } else {
    startHandler();
  }
};

export const openKustomerChat = () => {
  if (window.Kustomer && isKustomerInitialized) {
    window.Kustomer.open();
  } else if (!isKustomerInitialized) {
    console.error('Kustomer SDK not initialized yet');
    loadKustomerChat();
  } else {
    console.error('Kustomer SDK not loaded yet');
  }
};
