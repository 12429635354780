/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';

import toogleMenu from '../../assets/images/icon-toogle-menu-color-primary.svg';

import './toggleMenu.scss';

const ToggleMenu = ({ optionsMenu, onClick }) => {
  const handlerClick = (option) => {
    if (onClick) onClick(option.value);
  };
  return (
    <div id="toogle-menu">
      <button>
        <img className="toogle-menu-image" src={toogleMenu} alt="Menu" />
        <div className="menu">
          {optionsMenu.map((option) => (
            <div className="item">
              <img src={option.image} alt="" />
              <p className="option" onClick={() => handlerClick(option)}>{option.label}</p>
            </div>
          ))}
        </div>
      </button>
    </div>
  );
};

ToggleMenu.propTypes = {
  optionsMenu: PropTypes.arrayOf(({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    image: PropTypes.string,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleMenu;
